import {useMemo} from 'react';

import {
  DECISIONS_ACCOUNT,
  DECISIONS_ACCOUNT_MANAGER,
  DECISIONS_ACCOUNT_MANAGERS,
  DECISIONS_ACCOUNTS,
  DECISIONS_CARRIER_APPETITE,
  DECISIONS_DOCUMENTS,
  DECISIONS_EXECUTIVE_OVERVIEW,
  DECISIONS_INDUSTRIES,
  DECISIONS_INDUSTRY,
  DECISIONS_INDUSTRY_GROUP,
  DECISIONS_INDUSTRY_SUBGROUP,
  DECISIONS_KPI,
  DECISIONS_LOSS_RUNS,
  DECISIONS_MARKET_URL_BASE,
  DECISIONS_MARKETS,
  DECISIONS_O360SCORE,
  DECISIONS_O360SCORE_PRODUCER_URL,
  DECISIONS_PAGES_RENEWALS,
  DECISIONS_PRODUCER,
  DECISIONS_PRODUCERS,
  DECISIONS_PRODUCT,
  DECISIONS_PRODUCT_GROUP,
  DECISIONS_PRODUCT_INSURANCE_SEGMENT,
  DECISIONS_PRODUCT_SUPER_GROUP,
  DECISIONS_PRODUCTS,
  DECISIONS_REPORTS,
  DECISIONS_RETENTION,
  ENNABL_CHAT_AI,
  ENNABL_DECISIONS,
  ENNABL_GROWTH,
  generateStandalonePagePath,
  MarketType,
} from 'core/constants/paths';
import {SectionQueryParam} from 'modules/decisionsNext/constants/url';
import {useFeatureFlag, useIsExportHidden, useTenantFeatureFlag, useWithEnnablAI} from 'core/hooks/authHooks';
import {useIsDownSm} from 'utils/hooks/useMediaQuery';
import {isWebViewApp} from 'core/utils/webView';
import {FeatureFlags} from 'core/constants/featureFlags';

import {isDivider} from './utils';
import {IconKey, SidebarSection} from './types';

export const useSidebarSections = () => {
  const isMobile = useIsDownSm() || isWebViewApp();
  const isExportHidden = useIsExportHidden();
  const isUnisonTenant = useTenantFeatureFlag(FeatureFlags.UnisonTenant);
  const withDocuments = useFeatureFlag(FeatureFlags.Documents);
  const {isAIEnabled: withEnnablAI} = useWithEnnablAI();
  const withO360Score = useFeatureFlag(FeatureFlags.O360Score);
  const withGrowth = useFeatureFlag(FeatureFlags.ProducerPortal);
  const withKPI = useFeatureFlag(FeatureFlags.KPI);
  const withRetention = useFeatureFlag(FeatureFlags.RetentionPage);
  const withLossRuns = useFeatureFlag(FeatureFlags.LossRuns);

  return useMemo<SidebarSection[]>(
    () =>
      (
        [
          {
            title: 'Discover',
            items: [
              {
                icon: IconKey.Home,
                title: 'Home',
                to: ENNABL_DECISIONS,
                matchPattern: [
                  {
                    matchPattern: ENNABL_DECISIONS,
                    params: {end: true},
                  },
                ],
              },
              {
                icon: IconKey.AI,
                title: 'ennablAI',
                to: ENNABL_CHAT_AI,
                matchPattern: [{matchPattern: ENNABL_CHAT_AI}],
                isHidden: !withEnnablAI,
              },
              {
                icon: IconKey.Union,
                title: 'Growth',
                to: ENNABL_GROWTH,
                matchPattern: [{matchPattern: ENNABL_GROWTH}],
                isHidden: !withGrowth,
              },
              {
                icon: IconKey.KPI,
                title: 'KPIs',
                to: DECISIONS_KPI,
                matchPattern: [{matchPattern: DECISIONS_KPI}],
                isHidden: !withKPI,
              },
              {
                icon: IconKey.Prospects,
                title: 'Opportunities',
                to: generateStandalonePagePath({id: 'opportunities', tab: 'overview'}),
                matchPattern: [
                  {
                    matchPattern: generateStandalonePagePath({
                      id: 'opportunities',
                      tab: 'overview',
                      subTab: 'prospects',
                    }),
                  },
                ],
                isHidden: isMobile || withGrowth,
              },
              {
                icon: IconKey.O360Score,
                title: 'O360 Score',
                to: DECISIONS_O360SCORE,
                matchPattern: [{matchPattern: DECISIONS_O360SCORE}, {matchPattern: DECISIONS_O360SCORE_PRODUCER_URL}],
                isHidden: !withO360Score,
              },
              {
                icon: IconKey.Overview,
                title: 'Overview',
                to: DECISIONS_EXECUTIVE_OVERVIEW,
                matchPattern: [{matchPattern: DECISIONS_EXECUTIVE_OVERVIEW}],
                isHidden: isMobile,
              },
            ],
          },
          {
            title: 'Book',
            items: [
              {
                icon: IconKey.Accounts,
                title: 'Accounts',
                to: DECISIONS_ACCOUNTS,
                matchPattern: [{matchPattern: DECISIONS_ACCOUNTS}, {matchPattern: DECISIONS_ACCOUNT}],
              },
              {
                icon: IconKey.Documents,
                title: 'Documents',
                to: DECISIONS_DOCUMENTS,
                matchPattern: [{matchPattern: DECISIONS_DOCUMENTS}],
                isHidden: isExportHidden || !withDocuments,
              },
              {
                icon: IconKey.LossRuns,
                title: 'Loss Runs',
                to: DECISIONS_LOSS_RUNS,
                matchPattern: [{matchPattern: DECISIONS_LOSS_RUNS}],
                isHidden: !withLossRuns,
              },
              {
                icon: IconKey.Renewals,
                title: 'Renewals',
                to: DECISIONS_PAGES_RENEWALS,
                matchPattern: [{matchPattern: DECISIONS_PAGES_RENEWALS}],
              },
              {
                icon: IconKey.Reports,
                title: 'Report Studio',
                to: DECISIONS_REPORTS,
                matchPattern: [{matchPattern: DECISIONS_REPORTS}],
                isHidden: isExportHidden,
              },
              {
                icon: IconKey.Retention,
                title: 'Retention',
                to: DECISIONS_RETENTION,
                matchPattern: [{matchPattern: DECISIONS_RETENTION}],
                isHidden: !withRetention,
              },
            ],
          },
          {
            title: 'Insights',
            items: [
              {
                icon: IconKey.CarrierAppetite,
                title: 'Carrier Appetite',
                to: DECISIONS_CARRIER_APPETITE,
                matchPattern: [{matchPattern: DECISIONS_CARRIER_APPETITE}],
              },
              {
                icon: IconKey.Industries,
                title: 'Industries',
                to: DECISIONS_INDUSTRIES,
                matchPattern: [
                  {matchPattern: DECISIONS_INDUSTRY},
                  {matchPattern: DECISIONS_INDUSTRY_GROUP},
                  {matchPattern: DECISIONS_INDUSTRY_SUBGROUP},
                  {matchPattern: DECISIONS_INDUSTRIES},
                ],
              },
              {
                icon: IconKey.Markets,
                title: 'Markets',
                to: DECISIONS_MARKETS,
                matchPattern: [
                  {matchPattern: DECISIONS_MARKETS},
                  {matchPattern: `${DECISIONS_MARKET_URL_BASE}/${MarketType.ICO}`},
                  {matchPattern: `${DECISIONS_MARKET_URL_BASE}/${MarketType.BCO}`},
                ],
              },
              {
                icon: IconKey.Products,
                title: 'Products',
                to: DECISIONS_PRODUCTS,
                matchPattern: [
                  {matchPattern: DECISIONS_PRODUCT},
                  {matchPattern: DECISIONS_PRODUCT_GROUP},
                  {matchPattern: DECISIONS_PRODUCT_SUPER_GROUP},
                  {matchPattern: DECISIONS_PRODUCT_INSURANCE_SEGMENT},
                  {matchPattern: DECISIONS_PRODUCTS},
                ],
              },
            ],
          },
          {
            title: 'Network',
            items: [
              {
                icon: IconKey.AccountManagers,
                title: 'Account Managers',
                to: DECISIONS_ACCOUNT_MANAGERS,
                matchPattern: [{matchPattern: DECISIONS_ACCOUNT_MANAGERS}, {matchPattern: DECISIONS_ACCOUNT_MANAGER}],
              },
              {
                icon: IconKey.Producers,
                title: 'Producers',
                to: DECISIONS_PRODUCERS,
                matchPattern: [{matchPattern: DECISIONS_PRODUCER}, {matchPattern: DECISIONS_PRODUCERS}],
                isHidden: isUnisonTenant,
              },
            ],
          },
          {
            divider: true,
          },
          {
            items: [
              {
                icon: IconKey.Bookmark,
                title: 'Favorites',
                to: `${ENNABL_DECISIONS}?${SectionQueryParam.Home}Tab=favorites`,
                matchPattern: [],
              },
            ],
          },
        ] satisfies SidebarSection[]
      ).map((section: SidebarSection) =>
        isDivider(section)
          ? section
          : {
              title: section?.title,
              items: section?.items?.filter(item => !item?.isHidden),
            }
      ),
    [
      isMobile,
      isExportHidden,
      withEnnablAI,
      withGrowth,
      withKPI,
      withRetention,
      isUnisonTenant,
      withDocuments,
      withO360Score,
      withLossRuns,
    ]
  );
};
